.Carousel {
  width: 100%;
  /* height: 10rem; */
  display: flex;
  flex-direction: column;
  /* background-color: var(--floofe-main-color); */
  font-weight: 400;
}

.CarouselTitleRow {
  display: flex;
  flex-direction: row;
}

.CarouselTitle {
  font-weight: 700;
  font-size: 1.2rem;
  display: flex;
  line-height: 1.2rem;
  align-items: center;
  margin-left: 1rem;
  width: 80%;
}

.CarouselItems {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 1rem;
  overflow: scroll;
  align-items: center;
}

.CarouselItems::-webkit-scrollbar {
  display: none;
}

.CarouselItems > * {
  margin-right: 1rem;
}
