.OperationNode {
  background-color: var(--floofe-color-6);
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 600;
}

.OperationNodeSelected {
  background-color: var(--floofe-color-5);
}