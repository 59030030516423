.AlgoAddNodeMenu {
  position: absolute;
  width: 20rem;
  border-radius: 1rem;
  background-color: var(--floofe-color-6);
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
  cursor: pointer;
}

.AlgoAddNodeMenuButton {
  padding: 1rem;
  text-align: center;
}

.AlgoAddNodeMenu:hover .AlgoAddNodeMenuContent {
  display: block;
}

.AlgoAddNodeMenuContent {
  display: none;
  max-height: 50vh;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.AlgoAddNodeMenuContent::-webkit-scrollbar {
  display: none;
}

.AlgoAddNodeMenuItem {
  padding: 1rem;
  border-radius: 1rem;
}

.AlgoAddNodeMenuItem:hover {
  background-color: var(--floofe-color-5);
}
