.Feeds > * {
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.ActiveVideoArea {
  display: flex;
  border-radius: 1rem;
  max-width: 100%;
}

.ActiveVideoArea iframe {
  border-radius: 1rem;
  /* height: 50vh; */
}
