.FeedTitleRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.FeedTitleRowL {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-top: 0.5rem;
}

.FeedTitleRowR {
  display: flex;
  flex-direction: row;
  /* gap: 1rem; */
  margin-top: 0.5rem;
}

.FeedTitleRowR > * {
  margin-left: 1rem;
}

.FeedTitleRowR > *:nth-child(1) {
  margin-left: 0rem;
}

.FeedTitle {
  font-weight: 700;
  font-size: 1.2rem;
  display: flex;
  line-height: 1.2rem;
  align-items: center;
  margin-left: 1rem;
  color: var(--floofe-color-3);
  /* width: 80%; */
}

.FeedEditButton {
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  line-height: 1.2rem;
  align-items: center;
}
