.ChannelHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ChannelHeaderLeft {
  display: flex;
  flex-direction: row;
}

.ChannelHeaderRight {
  display: flex;
  flex-direction: row;
}

.ChannelHeaderRight > * {
  margin-left: 1rem;
}

.ChannelRefreshButton {
  cursor: pointer;
}

.ChannelTitle {
  font-weight: 700;
  font-size: 1.2rem;
  display: flex;
  line-height: 1.2rem;
  align-items: center;
  margin-left: 1rem;
  width: 80%;
}
