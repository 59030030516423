.EditBubble {
  cursor: pointer;
}

.EditFeedSplit {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.EditFeedSplit > .FeedItems {
  width: 67%;
  justify-content: left;
}

.EditFeedSplit > .EditFeedControls {
  width: 33%;
  min-width: 14rem;
  margin-top: 1rem;
}

.EditFeedControls > * {
  margin-bottom: 1rem;
}

@media only screen and (max-width : 700px) {
  .EditFeedSplit {
    flex-direction: column-reverse;
  }

  .EditFeedSplit > .FeedItems {
    width: 100%;
    justify-content: center;
  }

  .EditFeedSplit > .EditFeedControls {
    width: 100%;
    margin-top: 1rem;
  }
}
