.Header {
    height: var(--floofe-header-height);
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    /* background-color: rgb(36, 36, 36); */
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid var(--floofe-color-5);
    background-color: var(--floofe-color-7);
}

.HeaderSpacer {
    /* This is to accomodate the fixed Header element */
    height: var(--floofe-header-height);
    width: 100%;
}

.HeaderContent {
    width: var(--floofe-width);
    max-width: var(--floofe-max-width);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.HeaderLogo {
    font-family: 'Signika', sans-serif;
    background-image: linear-gradient(268.51deg, var(--floofe-color-1) -25.46%, #EDD2F3 85.3%);
    background-clip: text;
    color: transparent;
    font-size: 3rem;
    font-variant-caps: all-small-caps;
    text-shadow: 0.1rem 0.1rem black;
}

:root[data-theme=dark] .HeaderLogo {
    text-shadow: none;
}

.HeaderButtons {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.HeaderButton {
    font-size: 1rem;
    font-weight: 700;
    border-radius: .5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    padding: 0rem 2.5rem 0rem 2.5rem;
}

@media only screen and (max-width: 700px) {
    .HeaderButtons {
        gap: 0.8rem;
    }

    .HeaderButton {
        font-size: 0.8rem;
        font-weight: 700;
        border-radius: .5rem;
        height: 2rem;
        line-height: 2rem;
        padding: 0rem 0.8rem 0rem 0.8rem;
    }
}

.HeaderButton:hover {
    cursor: pointer;
}

.HeaderJoin {
    /* background-color: #fbeffe;
    color: #999; */
    color: var(--floofe-main-color);
    background-color: var(--floofe-color-0);
    border: solid 2px var(--floofe-main-color);
}

.HeaderLogin {
    color: var(--floofe-color-0);
    background-color: var(--floofe-main-color);
    border: solid 2px var(--floofe-main-color);
}

.HeaderLogin:hover {
    background-color: var(--floofe-color-2);
}
