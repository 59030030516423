.ChannelControllerSearchBox {
  width: 100%;
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: var(--floofe-color-5);
  border-radius: 10rem;
}

.ChannelControllerSearchBox > input {
  width: calc(100% - 1rem);
  height: 1.5rem;
}

.ChannelItemList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.ChannelItem {
  display: flex;
  flex-direction: column;
  width: 21%;
  margin-right: 2%;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 1rem; */
  padding: 1rem 0rem;
  border-radius: 1rem;
  cursor: pointer;
}

.ChannelItem:hover {
  background-color: var(--floofe-color-5);
}

.ChannelItemTitle {
  font-weight: 400;
  width: 100%;
  margin-top: 0.5rem;

  font-size: 0.75rem;
  line-height: 1rem;;
  height: 2rem;
  max-height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.ChannelItemX {
  background-color: var(--floofe-color-4);
  color: var(--floofe-color-6);
  /* border: solid 2px var(--floofe-color-0); */
  /* border: solid 2px var(--floofe-color-4); */
  border-radius: 10rem;
  width: 100%;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}