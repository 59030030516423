.FeedItems {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* flex-direction: row; */
  margin-top: 1rem;
  /* overflow: scroll; */
  align-items: center;
  justify-content: center;
}

.FeedItems > * {
  margin-bottom: 2rem;
  margin-right: 1rem;
  cursor: pointer;
}