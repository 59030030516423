.Bubble {
  display: flex;
  background-color: var(--floofe-main-color);
  height: 2rem;
  width: 2rem;
  min-width: 2rem;
  border-radius: 2rem;
  background-size: cover;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 2rem;
  color: var(--floofe-color-0);
  font-weight: 400;
  position: relative;
}

.BubbleDecoration {
  position: absolute;
  display: flex;
  top: -0.325rem;
  right: -0.325rem;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  /* background-color: var(--floofe-color-2); */
  border-radius: 1rem;
}

.BubbleDecoration > *{
  overflow: hidden;
}
