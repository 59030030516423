.VideoCard {
  display: flex;
  flex-direction: column;
  --floofe-video-card-width: 14rem;
  width: var(--floofe-video-card-width);
}


@media only screen and (max-width: 700px) {
  .VideoCard {
    --floofe-video-card-width: calc(var(--floofe-width) - 3rem);
    /* right: calc((100% - var(--floofe-width)) / 2); */
  }
}


.VideoCardImage {
  height: calc(var(--floofe-video-card-width) * 9/16);
  width: var(--floofe-video-card-width);

  background-size: cover;
  background-position: center;
  border-radius: 1rem 1rem 0rem 0rem;
}

/* .VideoCardImage img {
  height: calc(var(--floofe-video-card-width) * 9/16);
  width: var(--floofe-video-card-width);
  border-radius: 1rem 1rem 0rem 0rem;
} */

.VideoCardBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: var(--floofe-color-5);
  align-items: center;
}

.VideoCardCreator {
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.VideoCardDetails {
  display: flex;
  flex-direction: column;
  width: calc(100% - 4rem);
}

.VideoCardTitle {
  font-weight: 400;
  font-size: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.VideoCardStats {
  font-weight: 200;
  font-size: 0.7rem;
}
