.FeedController {
  background-color: var(--floofe-color-6);
  border-radius: 1rem;
  padding: 1rem;
}

.FeedController > * {
  margin-top: 0.5rem;
}

.ControllerName {

}

.ControllerInputs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ControllerInputs > input{
  width: 100%;
  margin-top: 0.5rem;
}


.ControllerInputs > input[type=range]{
  
}

