.SideBar {
  width: min(calc(var(--floofe-sidebar-width) * var(--floofe-width)), calc(var(--floofe-sidebar-width) * var(--floofe-max-width)));
  /* width: 30.4%; */
  display: flex;
  flex-direction: column;
  height: calc(100% - var(--floofe-header-height));
  overflow-y: scroll;
  position: fixed;
  top: var(--floofe-header-height);
  left: max(calc((100% - var(--floofe-width))/2), calc((100% - var(--floofe-max-width))/2));
}

@media only screen and (max-width: 700px) {
  .SideBar {
    width: var(--floofe-width);
    left: calc((100% - var(--floofe-width)) / 2);
    display: none;
  }
}

.SideBar::-webkit-scrollbar {
  display: none;
}


.SideBar > * {
  margin-top: 1rem;
}
